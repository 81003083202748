<template>
  <v-dialog v-model="dialog[entry.journal_entry_id]" width="1100"
    ><v-sheet class="pa-3">
      <v-container>
        <v-row class="mb-5">
          <v-col>
            <!--Close button-->
            <v-btn icon small class="float-right" @click="$emit('closeDialog')"
              ><v-icon small>mdi-close</v-icon></v-btn
            >
            <h3>Allocate Entry To Bank Transaction</h3>
          </v-col>
        </v-row>
        <!--Entry Summary-->
        <v-row>
          <v-col>
            <v-alert outlined>
              <v-container>
                <v-row dense>
                  <v-col>
                    <h3>Entry Summary</h3>
                  </v-col>
                </v-row>
                <!--Date-->
                <v-row dense>
                  <v-col lg="3" md="3" sm="3"><strong>Date:</strong></v-col>
                  <v-col lg="9" md="9" sm="9">{{
                    entry.journal_entry_date
                  }}</v-col>
                </v-row>
                <!--Entry Amount-->
                <v-row dense>
                  <v-col lg="3" md="3" sm="3"
                    ><strong>Total Amount:</strong></v-col
                  >
                  <v-col lg="9" md="9" sm="9">{{
                    formatAsCurrency("R", entryTotal)
                  }}</v-col>
                </v-row>
                <!--Allocated Amount-->
                <v-row dense v-if="selectedTransactions">
                  <v-col lg="3" md="3" sm="3"
                    ><strong>Amount Allocated:</strong></v-col
                  >
                  <v-col lg="9" md="9" sm="9">{{
                    formatAsCurrency("R", amountAllocated)
                  }}</v-col>
                </v-row>
              </v-container>
            </v-alert>
          </v-col>
        </v-row>
        <!--Error alert-->
        <v-row v-if="errorMessage">
          <v-col>
            <v-alert type="error" text color="error" dense>{{
              errorMessage
            }}</v-alert>
          </v-col>
        </v-row>
        <!--Transaction table-->
        <v-row>
          <v-col>
            <v-data-table
              v-model="selectedTransactions"
              dense
              :items="filteredTransactions"
              :headers="transactionHeaders"
              item-key="transaction_id"
              :sort-by="['transacion_date', 'best_match']"
              :sort-desc="[false, true]"
              multi-sort
              :items-per-page="100"
              show-select
              single-select
              :search="search"
              :loading="loadingTranscations"
              :item-class="suggestedMatchClass"
            >
              <!--Filters-->
              <template v-slot:top>
                <v-container>
                  <v-row>
                    <!--Best Match-->
                    <v-col sm="3" md="3" lg="3">
                      <v-checkbox
                        v-model="showBestMatches"
                        label="Show Best Matches Only"
                      ></v-checkbox>
                    </v-col>
                    <!--Bank Account Filter-->
                    <v-col sm="3" md="3" lg="3">
                      <v-autocomplete
                        :items="bankAccountList"
                        item-text="bank_account_name"
                        item-value="bank_account_id"
                        dense
                        outlined
                        v-model="bankAccount"
                        label="Bank Account"
                        append-icon="mdi-filter-variant"
                        clearable
                        @click:clear="clearBankAccount()"
                      ></v-autocomplete>
                    </v-col>
                    <!--Search-->
                    <v-col sm="3" md="3" lg="3">
                      <v-text-field
                        dense
                        outlined
                        append-icon="mdi-magnify"
                        v-model="search"
                        placeholder="Search..."
                      ></v-text-field>
                    </v-col>
                    <!--Reconcile Button-->
                    <v-col sm="3" md="3" lg="3">
                      <v-btn color="primary" @click="allocateJournalEntry()"
                        ><v-icon small left>mdi-check</v-icon> Allocate Journal
                        Entry</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <!--Description format-->
              <template v-slot:item.transaction_description="props">
                <tr>
                  <td class="truncate">
                    {{ props.item.transaction_description }}
                  </td>
                </tr>
              </template>
              <!--Amount-->
              <template v-slot:item.transaction_amount="props">
                {{ formatAsCurrency("R", props.item.transaction_amount) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet></v-dialog
  >
</template>
<script>
import { formatAsCurrency } from "../../../../composables/external";
import db from "../../../../components/firebaseInit";
export default {
  name: "ReconcileJournalEntryModal",
  props: ["dialog", "entry"],
  data() {
    return {
      search: "",
      loadingTranscations: false,
      transactions: [],
      bankAccount: null,
      bankAccountList: [],
      selectedTransactions: null,
      showBestMatches: false,
      errorAlert: false,
      errorMessage: null,
      transactionHeaders: [
        {
          text: "Transaction Number",
          value: "transaction_number",
          align: "start",
        },
        {
          text: "Date",
          value: "transaction_date",
        },
        {
          text: "Account",
          value: "bank_account_name",
        },
        {
          text: "Description",
          value: "transaction_description",
        },
        {
          text: "Amount",
          value: "transaction_amount",
          align: "right",
        },
      ],
    };
  },
  computed: {
    filteredTransactions() {
      //  Create array of filter functions
      const filters = {
        bankAccount: (item) => item.bank_account_id === this.bankAccount,
        bestMatch: (item) => item.best_match > 0,
      };
      const selected = [];
      //  Set conditions to apply filters to the array
      this.bankAccount ? selected.push(filters.bankAccount) : null;
      this.showBestMatches ? selected.push(filters.bestMatch) : null;
      const output = this.transactions.filter((item) =>
        selected.every((filter) => filter(item))
      );
      return output;
    },
    //
    entryTotal() {
      return this.entry.line_items.reduce((total, item) => {
        return total + item.entry_amount;
      }, 0);
    },
    //
    amountAllocated() {
      return this.selectedTransactions.reduce(
        (total, item) => total + item.transaction_amount,
        0
      );
    },
  },
  created() {
    this.getBankAccounts();
    if (this.transactions.length === 0) {
      this.getBankTransactions();
    }
  },
  methods: {
    formatAsCurrency,
    //
    getBankTransactions() {
      const docRef = db
        .collection("bank_transactions")
        .where("transaction_allocation_status", "==", "No");

      docRef.onSnapshot((snapshot) => {
        this.transactions = [];
        this.transactions = snapshot.docs.map((doc) => {
          // Add best match criteria to entry
          let best_match = 0;
          // 1) Amount
          best_match +=
            doc.data().transaction_amount === this.entryTotal ? 1 : 0;
          // 2) Date
          best_match +=
            doc.data().transaction_date === this.entry.journal_entry_date
              ? 1
              : 0;
          return { ...doc.data(), best_match: best_match };
        });
      });
    },
    //
    getBankAccounts() {
      const bankArray = ["bank_accounts", "speed_points", "petty_cash"];
      bankArray.map(async (x) => {
        const docRef = db.collection(x);
        try {
          const snapshot = await docRef.get();
          snapshot.docs.forEach((doc) => {
            this.bankAccountList.push({
              ...doc.data(),
            });
          });
        } catch (error) {
          return new Error(error);
        }
      });
    },
    //
    bestMatchFilter() {},
    //
    clearBankAccount() {
      this.bankAccount = null;
    },
    // Format suggested matches in data table
    suggestedMatchClass(item) {
      return item.best_match ===
        Math.max(...this.transactions.map((o) => o.best_match))
        ? "green lighten-4"
        : "";
    },
    //
    allocateJournalEntry() {
      // Throw error message
      if (!this.selectedTransactions) {
        this.errorAlert = true;
        this.errorMessage =
          "Please select a bank transaction to allocate this entry towards.";
        return;
      }
      if (this.amountAllocated !== this.entryTotal) {
        this.errorAlert = true;
        this.errorMessage =
          "The entry amount does not equal the total of the selected bank transactions.";
        return;
      }
      this.errorAlert = false;
    },
  },
};
</script>
<style scoped>
.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.3s;
}
</style>